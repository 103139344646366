import { CanActivateFn } from '@angular/router';
import { AppService } from '../app/app.service';
import { Injectable, OnInit, inject } from '@angular/core';

@Injectable()
export class CheckRouteService {
  public routePage: any;
  constructor(private appService: AppService) {}

  async canActivate(): Promise<boolean> {
    let canRoute: boolean = true;
    await this.appService.pageData.subscribe((data) => {
      if (data['maintenance'].showMaintenance) {
        canRoute = false;
        window.location.href = '/maintenance';
      }
    });
    return canRoute;
  }
}

export const PageGuard: CanActivateFn = (route, state) => {
  return inject(CheckRouteService).canActivate();
};

import { Component } from '@angular/core';


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {

  slides = [
    {img: "https://awt-site.s3.us-east-2.amazonaws.com/laser-engraving-2.jpeg"},
    {img: "https://awt-site.s3.us-east-2.amazonaws.com/laser-engraving-2.jpeg"},
    {img: "https://awt-site.s3.us-east-2.amazonaws.com/laser-engraving-2.jpeg"},
    {img: "https://awt-site.s3.us-east-2.amazonaws.com/laser-engraving-2.jpeg"},
    {img: "https://awt-site.s3.us-east-2.amazonaws.com/laser-engraving-2.jpeg"},
    {img: "https://awt-site.s3.us-east-2.amazonaws.com/laser-engraving-2.jpeg"},
    {img: "https://awt-site.s3.us-east-2.amazonaws.com/laser-engraving-2.jpeg"},
  ];

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 4,
    "autoplay": false,
    "infinite": true,
  }

}

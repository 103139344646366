<div class="safety-section-container">
  <div class="incidents-info">
    <div class="incident-EMR">
      <span>0</span>
    </div>
    <div class="safety-info">
      <span class="top">ZERO</span>
      <span class="middle">Incidents</span>
      <span class="bottom">Safety comes first!!</span>
    </div>
    <app-marker letter="S" word="afety" wordColor="white"></app-marker>
  </div>
  <div class="right-photo">
    <img
      class="safety-img"
      [src]="safetyData?.img"
      alt=""
    />
  </div>
</div>

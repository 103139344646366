<div class="clients">
  <div class="clients__bkg-img">
    <div class="clients__overlay"></div>
    <img [src]="clientsData?.bkg" alt="" />
  </div>

  <div class="clients__container">
    <div class="clients__container__floating-img">
      <img [src]="clientsData?.floatingImg" alt="" />
    </div>
    <div class="clients__container__top">
      <div class="clients__container__top__content">
        <h1>Clients</h1>
        <h4>Empowering Industries, One Partner at a Time.</h4>
      </div>

      <div class="clients__container__cards-content">
        <div class="clients__container__cards-content__card">
          <div (click)="openModal('a')" class="clients__container__cards-content__clients-box">
            <h1>A - M</h1>
            <h3>Click here to View clients</h3>
          </div>

          <div *ngIf="customerReviewsA" class="blurb">
            <h1>Check out what our customers are saying!</h1>
            <i>
              "{{customerReviewsA}}"
            </i
            >
          </div>
        </div>
        <div class="clients__container__cards-content__card">
          <div (click)="openModal('n')" class="clients__container__cards-content__clients-box">
            <h1>N - Z</h1>
            <h3>Click here to View clients</h3>
          </div>
          <div *ngIf="customerReviewsB" class="blurb">
            <h1>Discover why our customers absolutely adore us!</h1>
            <i>
              "{{customerReviewsB}}"
             </i
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

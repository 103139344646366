<div class="clientsStepper">
    <mat-horizontal-stepper labelPosition="bottom" linear="false" #stepper>
      <mat-step [stepControl]="fg">
        <form *ngIf="fg" [formGroup]="fg">
          <ng-template matStepLabel>Choose a {{ data.selected }}</ng-template>
          <div class="clientsStepper__card-container">
            <div (click)="handleSelect(stepper, client?.name, client?.pics)" *ngFor="let client of data.clients" class="cardWrap">
              <div class="card">
                <div class="bgImg">
                  <img [src]="client?.logo" alt="" />
                </div>
                <div class="cardInfo">
                  <h3 class="cardTitle">{{ client?.name }}</h3>
                  <p>{{ client?.info }}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button (click)="goNext($event, stepper)" mat-button>
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="fg">
        <form *ngIf="items.length >= 1" [formGroup]="fg">
          <ng-template matStepLabel>{{ selectedPhotoName }} photos</ng-template>
          <div class="grid-container">
            <gallery gallerize class="grid-item" [items]="items"></gallery>
          </div>
          <div>
            <button mat-button (click)="goBack($event, stepper)">Back</button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  
import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';
import Typed from 'typed.js';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  aboutData: any = ''
  slides:any[] = [
    {view: 'top'},
    {view: 'next'},
    {view: 'last'}
  ]
  count = 0;
  scrollTxt = 'Scroll'

  constructor(private appService: AppService) {
    this.appService.triggerLoader(true)
  }

  ngOnInit(): void {
      this.appService.pageData.subscribe((page) => {
        this.aboutData = page['about']
        console.log('about', this.aboutData)
      })

      setTimeout(() => {
        this.appService.triggerLoader(false)
      }, 2000)
  }

  ngAfterViewInit(): any {
    const typedLast = new Typed('#type3', {
      strings: [
        `${this.aboutData?.content?.last}`,
      ],
      startDelay: 2000,
      typeSpeed: 100,
    });



    return {
      typedLast
    };
  }

  seeMore() {
    if (this.count === this.slides.length) {
      this.count = 0;
    } else {
      this.count++;
    }
    this.scrollTxt = `Scroll to ${this.slides[this.count].view}`;
    document.getElementById(this.slides[this.count].view)?.scrollIntoView();
  }
}

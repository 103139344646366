<div class="about">
  <div *ngIf="aboutData?.office" class="about__img-container">
    <div
      [id]="aboutImg?.id"
      *ngFor="let aboutImg of aboutData?.office"
      class="about__img-container__imgs"
    >
      <div class="about-top-overlay"></div>
      <img [src]="aboutImg?.img" alt="" />
      <h2>{{ aboutImg?.name }}</h2>
      <h3>{{ aboutImg?.title }}</h3>
      <div class="about-bottom-overlay"></div>
    </div>
    <div class="about__btn">
      <button (click)="seeMore()" class="cta">
        <span>Scroll</span>
        <svg viewBox="0 0 13 10" height="10px" width="15px">
          <path d="M1,5 L11,5"></path>
          <polyline points="8 1 12 5 8 9"></polyline>
        </svg>
      </button>
    </div>
  </div>
  <div class="about__content">
    <div class="about__content__img">
      <img src="../../../assets/img/tri.svg" alt="">
    </div>
    <h1>About Sav's Welding</h1>
    <div class="about__content__container">
      <h4>{{aboutData?.content?.main}}</h4>
      <h4>{{aboutData?.content?.secondary}}</h4>
      <h5><i id="type3">""</i></h5>
    </div>
    
    <h1>Meet Our Team</h1>
  </div>
  <div class="about__crew-container">
    <div class="about__crew-img">
      <img [src]="aboutData?.crew.ironWorkers" alt="" />
      <h2>{{aboutData?.crew.ironTitle}}</h2>
      <div class="about-bottom-overlay"></div>
    </div>
    <div class="about__crew-content">
      {{aboutData?.crew.ironContent}}
    </div>
  </div>

  <div class="about__crew-container">
    <div class="about__crew-content">
      {{aboutData?.crew.welderContent}}
    </div>
    <div class="about__crew-img">
      <img [src]="aboutData?.crew.welders" alt="" />
      <h2>{{aboutData?.crew?.welderTitle}}</h2>
      <div class="about-bottom-overlay"></div>
    </div>
  </div>
</div>

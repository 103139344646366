import { Component, OnInit, AfterContentInit, ChangeDetectorRef, AfterContentChecked, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/services/app/app.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit, AfterViewInit {
  clientsData: any = '';
  clients: any = '';
  customerReviewsA: any[] = [];
  customerReviewsB: any[] = [];
  secondReviews: any[] = [];

  constructor(private appService: AppService, private cdref: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.appService.pageData.subscribe((page) => {
      this.clientsData = page['clientsPage'];
      this.clients = page['clients']

      if (this.clientsData?.customerReviews)  this.customerReviewsA = [];
      if (this.clientsData?.customerReviews)  this.customerReviewsB = [];
     
      setTimeout(() => {
        this.appService.triggerLoader(false)
      }, 2000)
    });
  }

  ngAfterViewInit(): void {
    if (this.clientsData?.customerReviews)  this.customerReviewsA = this.clientsData.customerReviews[this.randomNumber(0, 5)];
    if (this.clientsData?.customerReviews)  this.customerReviewsB = this.clientsData.customerReviews[this.randomNumber(5, 10)];
    this.cdref.detectChanges();
  }

  randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  openModal(selected: string) {
    let start = 0;
    let stop = 0;
    if (selected === 'a') {
      start = 0;
      stop = 65;
    }

    if (selected === 'n') {
      start = 65;
      stop = 99;
    }
    
    this.appService.openModal.next({
      type: 'clientsStepper',
      selected: 'Client',
      clients: this.clients.slice(start, stop),
      width: '100%'
    })
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-section-marker',
  templateUrl: './section-marker.component.html',
  styleUrls: ['./section-marker.component.scss']
})
export class SectionMarkerComponent {

}

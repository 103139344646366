<div class="cert">
  <div class="cert__bkg-img">
    <div class="cert__overlay"></div>
    <img [src]="certData?.bkg" alt="" />
    <h1>Certifications</h1>
    <h4>built with credibility and trust</h4>
  </div>

  <div class="cert__headline">
    <h2 class="cert__headline__title">{{certData?.mission?.title}}</h2>
    <div class="cert__headline__content">
      {{certData?.mission?.firstParagraph}}
    </div>
    <div class="cert__headline__content">
      {{certData?.mission?.secondParagraph}}
    </div>
    <div class="cert__headline__content">
      {{certData?.mission?.thirdParagraph}}
    </div>
  </div>

  <div class="cert__container">
    <h4>Our Goals</h4>
    <div class="cert__container__cards">
      <div class="cert__container__cards__card">
        <h1 class="goals-title">
          {{ certData?.safety.header }}
          <svg
            width="100px"
            height="100px"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#fec400"
          >
            <path
              d="M8.5 4H6a2 2 0 00-2 2v14a2 2 0 002 2h6M15.5 4H18a2 2 0 012 2v9"
              stroke="#fec400"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
            <path
              d="M8 6.4V4.5a.5.5 0 01.5-.5c.276 0 .504-.224.552-.496C9.2 2.652 9.774 1 12 1s2.8 1.652 2.948 2.504c.048.272.276.496.552.496a.5.5 0 01.5.5v1.9a.6.6 0 01-.6.6H8.6a.6.6 0 01-.6-.6z"
              stroke="#fec400"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
            <path
              d="M15.5 20.5l2 2 5-5"
              stroke="#fec400"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </h1>
        <div class="safety-score">
          <span class="goals-score"> {{ certData?.safety.score }}</span>
          <div class="safety-data">
            <span class="top">{{ certData?.safety.top }}</span>
            <span class="middle">{{ certData?.safety.middle }}</span>
            <span class="bottom">{{ certData?.safety.bottom }}</span>
          </div>
        </div>
      </div>
      <div class="cert__container__cards__card">
        <h1 class="goals-title">
          {{ certData?.emr.header }}
          <svg
            width="100px"
            height="100px"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#fec400"
          >
            <path
              d="M11.576 1.424a.6.6 0 01.848 0l10.152 10.152a.6.6 0 010 .848L12.424 22.576a.6.6 0 01-.848 0L1.424 12.424a.6.6 0 010-.848L11.576 1.424zM12 8v4M12 16.01l.01-.011"
              stroke="#fec400"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </h1>
        <div class="safety-score">
          <span class="goals-score">{{ certData.emr.score }}</span>
          <div class="emr-data">
            <span class="top">{{ certData?.emr.top }}</span>
            <span class="middle">{{ certData?.emr.middle }}</span>
            <span class="bottom">{{ certData?.emr.bottom }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <h4>Our Certifications</h4>
    <div class="section-cards-overlay"></div>
    <div class="section-cards">
      <div
        *ngFor="let cert of certData?.certifications"
        class="section__certifications-section__content-cards"
      >
        <div class="section__certifications-section__content-cards__logo">
          <img [src]="cert?.logo" alt="" />
        </div>
        <div class="section__certifications-section__content-cards__letter">
          {{ cert?.letter }}
        </div>
        <div
          class="section__certifications-section__content-cards__description"
        >
          {{ cert?.name }}
        </div>
      </div>
    </div>
  </div>
</div>

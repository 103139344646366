import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent {
  servicesData: any = '';

  constructor(private appService: AppService) {
    this.appService.triggerLoader(true)
  }

  ngOnInit(): void {
    this.appService.pageData.subscribe((page) => {
      this.servicesData = page['services'];
      console.log('services', this.servicesData);
    });
    
    setTimeout(() => {
      this.appService.triggerLoader(false)
    }, 2000)
  }

}

import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.scss']
})
export class SafetyComponent {

}

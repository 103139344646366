import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';


@Component({
  selector: 'app-certifications-section',
  templateUrl: './certifications-section.component.html',
  styleUrls: ['./certifications-section.component.scss']
})
export class CertificationsSectionComponent {
  certificationsData: any = ''

  constructor(private appService: AppService) {}

  ngOnInit(): void {
      this.appService.pageData.subscribe((page) => {
        this.certificationsData = page['certifications']
      })
  }
}

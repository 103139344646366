import { Component, OnInit } from '@angular/core';
import { AppService } from './services/app/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'client';
  showLoader = true;

  constructor(private appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.getData();

    this.appService.isLoading.subscribe((v) => {
      this.showLoader = v
    })
  }
}

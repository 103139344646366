<div class="footer-container">
  <div class="left-side">
    <div class="logo">
      <img
        class="logo"
        src="https://sws-site.s3.us-east-2.amazonaws.com/savs-logo.png"
        alt=""
      />
    </div>
    <div class="description">
      <span>11811 Pleasant Street, Detroit MI 48217</span>
    </div>
    <div class="description">
      <span>Sav's Welding Services, Inc. © 2024</span>
      <p>
        <span class="powered-by">Powered by: CMJ Software</span>
      </p>
      
    </div>
  </div>
  <div class="right-side">
    <div class="contact-info">
      <div class="top-section">
        <span>Phone: 313-841-3430</span>
        <p>
          <span>Fax: 313-841-4764</span>
        </p>
        <p></p>
      </div>

      <div class="bottom-section">
        <span>Email: admin@savswelding.com</span>
        <p>
          <span></span>
        </p>
      </div>
    </div>
    <!-- <div class="social-media">
      <span>Facebook</span>
      <p>
        <span>Instagram</span>
      </p>
      <p>
        <span></span>
    </div> -->
  </div>
</div>

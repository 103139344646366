import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesSectionComponent } from './components/services-section/services-section.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SectionMarkerComponent } from './components/section-marker/section-marker.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { CertificationsComponent } from './pages/certifications/certifications.component';
import { OurCommunityComponent } from './pages/our-community/our-community.component';
import { CertificationsSectionComponent } from './components/certifications-section/certifications-section.component';
import { ClientsSectionComponent } from './components/clients-section/clients-section.component';
import { SafetySectionComponent } from './components/safety-section/safety-section.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { MarkerComponent } from './components/marker/marker.component';
import { ProjectComponent } from './pages/project/project.component';
import { ModalComponent } from './components/modal/modal.component';
import { ProjectsPhotoStepperComponent } from './components/projects-photo-stepper/projects-photo-stepper.component';
import { GALLERY_CONFIG, GalleryConfig } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxConfig, LightboxModule } from 'ng-gallery/lightbox';
import { LoaderComponent } from './components/loader/loader.component';
import { ClientsSectionStepperComponent } from './components/clients-section-stepper/clients-section-stepper.component';
import { DownForMaintenanceComponent } from './pages/down-for-maintenance/down-for-maintenance.component';
import { CheckRouteService } from './services/page/page.guard';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesSectionComponent,
    NavbarComponent,
    FooterComponent,
    SectionMarkerComponent,
    AboutUsComponent,
    ClientsComponent,
    OurServicesComponent,
    CertificationsComponent,
    OurCommunityComponent,
    SafetySectionComponent,
    CertificationsSectionComponent,
    CarouselComponent,
    ClientsSectionComponent,
    ProjectsComponent,
    MarkerComponent,
    ProjectComponent,
    ModalComponent,
    ProjectsPhotoStepperComponent,
    LoaderComponent,
    ClientsSectionStepperComponent,
    DownForMaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    MatStepperModule,
    MatRadioModule,
    MatButtonModule,
    MatProgressBarModule,
    LightboxModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [CookieService,
    CheckRouteService,
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: true,
        imageSize: 'cover'
      } as GalleryConfig
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        exitAnimationTime: 1000
      } as LightboxConfig
    }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}

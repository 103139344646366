import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
})
export class CertificationsComponent {
  certData: any = '';



  constructor(private appService: AppService) {
    this.appService.triggerLoader(true)
  }

  ngOnInit(): void {
    this.appService.pageData.subscribe((page) => {
      this.certData = page['certificationsPage'];
      console.log('Certifications Page', this.certData);
    });

    setTimeout(() => {
      this.appService.triggerLoader(false)
    }, 2000)
    
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss'],
})
export class ServicesSectionComponent implements OnInit {
  servicesData: any = '';
  tiles: any = [];
  count = 0;
  isSeeMore = false;
  isPlaying = false;

  slides: any[] = [
    {
      start: 0,
      stop: 3,
    },
    {
      start: 3,
      stop: 6,
    },
    {
      start: 6,
      stop: 7,
    },
  ];

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.pageData.subscribe((page) => {
     if (page) {
      this.servicesData = page['services'];
      this.tiles = page['services'].tiles.slice(0, 3);
     }
    });

  }

  seeMore() {
    if (this.count === this.slides.length) {
      this.count = 0;
    } else {
      this.count++;
    }

    const start = this.slides[this.count].start;
    const stop = this.slides[this.count].stop;

    this.tiles = this.servicesData.tiles.slice(start, stop);
  }

  routeTo(r: string) {
    this.appService.routeTo(r);
  }
}

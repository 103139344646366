<div class="certifications-section">
  <div class="certifications-overlay"></div>
  <div *ngFor="let cert of certificationsData" class="certifications-section__content-cards">
    <div class="certifications-section__content-cards__logo">
      <img [src]="cert?.logo" alt="">
    </div>
    <div class="certifications-section__content-cards__letter">
      {{cert?.letter}}
    </div>
    <div class="certifications-section__content-cards__description">
      {{cert?.name}}
    </div>
  </div>
  <app-marker letter="C" word="ertifications" wordColor="white"></app-marker>
</div>

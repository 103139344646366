import { Component } from '@angular/core';

@Component({
  selector: 'app-our-community',
  templateUrl: './our-community.component.html',
  styleUrls: ['./our-community.component.scss']
})
export class OurCommunityComponent {

}

<div *ngIf="servicesData" class="services-section-container">
  <div class="services-section-left-side">
    <!-- background video -->
    <vg-player>
      <video
        *ngIf="servicesData.vid"
        oncanplay="this.play()"
        onloadedmetadata="this.muted = true"
        [vgMedia]="$any(media)"
        #media
        #videoPlayer
        class="services-video"
        playsinline
        id="savsIntroVideo"
        preload="auto"
        [autoplay]="true"
        [loop]="true"
      >
        <source [src]="servicesData.vid" type="video/mp4" />
      </video>
    </vg-player>

    <div class="services-section-left-side-main-text">
      <h1>{{ servicesData.title }}</h1>
      <h3>
        {{ servicesData.subtitle }}
      </h3>
      <div class="services-section-left-side-buttons">
        <button (click)="routeTo('projects')" class="button type1">
          <span class="btn-txt">VIEW OUR WORK</span>
        </button>
        <button (click)="routeTo('clients')" class="button type1">
          <span class="btn-txt">VIEW TESTIMONIALS</span>
        </button>
      </div>
    </div>
    <app-marker letter="S" word="ervice" wordColor="white"></app-marker>
  </div>
  <div class="services-section-right-side">
    <h1>OUR SERVICES</h1>
    <div *ngIf="tiles" class="services-tiles">
      <div
        (click)="routeTo('services')"
        *ngFor="let service of tiles"
        class="cardWrap"
      >
        <div class="card">
          <div class="bgImg">
            <img [src]="service?.img" alt="" />
          </div>
          <div class="cardInfo">
            <h3 class="cardTitle">{{ service?.name }}</h3>
            <p>{{ service?.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="services-section-right-side__btn">
    <button (click)="seeMore()" class="button srv-btn type1">
      <span class="btn-txt">SHOW MORE SERVICES</span>
    </button>
  </div>
</div>

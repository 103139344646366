import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-down-for-maintenance',
  templateUrl: './down-for-maintenance.component.html',
  styleUrls: ['./down-for-maintenance.component.scss']
})
export class DownForMaintenanceComponent implements OnInit {
  @ViewChild('singleVideo') videoplayer?: ElementRef;
  maintenanceData: any = ''

  constructor(private appService: AppService) {
    this.appService.triggerLoader(true)
  }

  ngOnInit(): void {
      this.appService.pageData.subscribe((page) => {
        this.maintenanceData = page['maintenance']
        console.log('DownForM', this.maintenanceData)
      })

      setTimeout(() => {
        this.appService.triggerLoader(false)
      }, 2000)

      console.log('video', this.videoplayer)
  }
}

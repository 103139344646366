import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  projectData: any = ''

  constructor(private appService: AppService) {
    this.appService.triggerLoader(true)
  }

  ngOnInit(): void {
      this.appService.pageData.subscribe((page: any) => {
        this.projectData = page['projectPage']
        console.log('Project Page', this.projectData)
      })

      setTimeout(() => {
        this.appService.triggerLoader(false)
      }, 2000)
  }

  openModal(selected: string) {
    this.appService.openModal.next({
      type: 'projectsStepper',
      selected,
      projects: this.projectData[selected],
      width: '100%'
    })
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { CertificationsComponent } from './pages/certifications/certifications.component';
import { SafetyComponent } from './pages/safety/safety.component';
import { OurCommunityComponent } from './pages/our-community/our-community.component';
import { ProjectComponent } from './pages/project/project.component';
import { DownForMaintenanceComponent } from './pages/down-for-maintenance/down-for-maintenance.component';
import { PageGuard } from './services/page/page.guard';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'services',
    component: OurServicesComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'certifications',
    component: CertificationsComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'safety',
    component: SafetyComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'community',
    component: OurCommunityComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'projects',
    component: ProjectComponent,
    canActivate: [PageGuard],
  },
  {
    path: 'maintenance',
    component: DownForMaintenanceComponent,
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="project">
  <div class="project__bkg-img">
    <div class="project__overlay"></div>
    <img [src]="projectData?.bkg" alt="" />
    <h1>Projects</h1>
    <h4>built to perform</h4>
  </div>
  <h3 class="see">See Our Work</h3>
  <h5>
    Welcome to our welding projects collection! Here, we showcase our
    craftsmanship and expertise in welding various materials into a sturdy and
    aesthetically pleasing masterpiece. Our team of skilled welders is
    experienced in working with different types of metals, including but not
    limited to stainless steel, aluminum, and iron. From small neighborhoods
    to large-scale industrial structures, we take pride in every project we
    undertake. Our welding techniques are of the highest quality, ensuring the
    durability and longevity of every product we create. Please take a look at
    our gallery of welding projects below.
  </h5>
  <div class="project__four-sq">
    <div class="project__four-sq__lf">
      <div (click)="openModal('stadiums')" class="project__four-sq__lf__one">
        <div class="overlay-one"></div>
        <img [src]="projectData?.square?.one?.bkg" alt="" />
        <h1>Stadiums</h1>
      </div>
      <div class="project__four-sq__lf__container">
        <div (click)="openModal('buildings')" class="project__four-sq__lf__two">
          <div class="overlay-two"></div>
          <img [src]="projectData?.square?.two?.bkg" alt="" />
          <h1>Buildings</h1>
        </div>
        <div
          (click)="openModal('neighborhoods')"
          class="project__four-sq__lf__three"
        >
          <div class="overlay-three"></div>
          <img [src]="projectData?.square?.three?.bkg" alt="" />
          <h1>Neighborhoods</h1>
        </div>
      </div>
    </div>
    <div class="project__four-sq__rh">
      <div (click)="openModal('steelWork')" class="project__four-sq__rh__one">
        <div class="overlay-four"></div>
        <img [src]="projectData?.square?.four?.bkg" alt="" />
        <h1>Steel Work</h1>
      </div>
    </div>
  </div>
</div>

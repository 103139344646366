<div class="app-navbar">
  <div (click)="routeTo('about-us')" class="nav-button">About</div>
  <div (click)="routeTo('clients')" class="nav-button">Clients</div>
  <div (click)="routeTo('services')" class="nav-button">Services</div>
  <img
    (click)="routeTo('/')"
    class="logo"
    src="https://sws-site.s3.us-east-2.amazonaws.com/savs-logo.png"
    alt=""
  />
  <div (click)="routeTo('certifications')" class="nav-button">
    Certifications
  </div>
  <div (click)="routeTo('projects')" class="nav-button">Projects</div>
  <!-- <div (click)="routeTo('community')" class="nav-button">Community</div> -->
</div>

<div class="app-mobile">
<div class="app-mobile__menu">
    <img
    (click)="routeTo('/')"
    class="logo"
    src="https://sws-site.s3.us-east-2.amazonaws.com/savs-logo.png"
    alt=""
  />
  <svg
    (click)="showMenu()"
    width="24px"
    height="24px"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="#000000"
  >
    <path
      d="M3 5H11"
      stroke="#000000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M3 12H16"
      stroke="#000000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M3 19H21"
      stroke="#000000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</div>
  <div *ngIf="isMenuShowing" class="app-mobile__links">
    <button (click)="routeTo('about-us')" class="button srv-btn type1">
        <span class="btn-txt">ABOUT</span>
      </button>
      <button (click)="routeTo('clients')" class="button srv-btn type1">
        <span class="btn-txt">CLIENTS</span>
      </button>
      <button (click)="routeTo('services')" class="button srv-btn type1">
        <span class="btn-txt">SERVICES</span>
      </button>
      <button (click)="routeTo('certifications')" class="button srv-btn type1">
        <span class="btn-txt">CERTIFICATIONS</span>
      </button>
      <button (click)="routeTo('projects')" class="button srv-btn type1">
        <span class="btn-txt">PROJECTS</span>
      </button>
  </div>
</div>

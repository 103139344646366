<div class="clients-container">
  <div class="section-title">
    <h1><span>See</span> Our Clients</h1>
  </div>
  <div class="clients">
    <div class="clients-container__clients">
      <div class="clients-tile-section">
        <div *ngFor="let client of clientsData" class="clients-tile-section__tiles" (click)="handleClick(client)">
          <div class="clients-tile-section__tiles__letter">{{client?.letter}}</div>
          <div class="clients-tile-section__tiles__name">{{client?.name}}</div>
        </div>
      </div>
      <div class="carosouel-buttons">
           
        <button (click)="goPrevious()" class="button-left">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path
              d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
            />
          </svg>
        </button>
        <button (click)="goNext()" class="button-right">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path
              d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
            />
          </svg>
        </button>
      </div>
    </div>
  
    <div class="clients-info-section">
      <h1>{{clientSpotlight?.name || defaultSpotlight?.name}}</h1>
      <div class="client-detail">
        {{clientSpotlight?.info || defaultSpotlight?.info}}
      </div>
      <div class="client-info-overlay"></div>
      <img
        class="client-info-pic"
        [src]="clientSpotlight?.logo || defaultSpotlight?.logo"
        [alt]="clientSpotlight?.name"
      />
    </div>
  </div>
  <app-marker letter="C" word="lients" wordColor="blue"></app-marker>
</div>

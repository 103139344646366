import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-clients-section',
  templateUrl: './clients-section.component.html',
  styleUrls: ['./clients-section.component.scss'],
})
export class ClientsSectionComponent {
  pageData: any = '';
  clientsData: any = '';
  clientSpotlight: any = '';
  defaultSpotlight: any = '';
  slides: any[] = [
    {
      start: 0,
      stop: 15,
    },
    {
      start: 15,
      stop: 30,
    },
    {
      start: 30,
      stop: 45,
    },
    {
      start: 45,
      stop: 60,
    },
    {
      start: 60,
      stop: 75,
    },
    {
      start: 75,
      stop: 90,
    },
  ];
  slideCount = 0;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.pageData.subscribe((page: any) => {
        if(page) {
          this.pageData = page['clients'];
          this.clientsData = page['clients'].slice(0, 15);
          this.defaultSpotlight = this.clientsData[0];
          console.log('client', this.clientsData.slice(0, 9));
        }
    });
  }

  goNext() {
    if (this.slideCount === this.slides.length) {
      this.slideCount = 0;
    } else {
      this.slideCount++;
    }
    const start = this.slides[this.slideCount].start;
    const stop = this.slides[this.slideCount].stop;

    this.clientsData = this.pageData.slice(start, stop);
  }

  goPrevious() {  
    if (this.slideCount <= 0 ) {
      this.slideCount = 0;
    } else {
      this.slideCount--;
    }

    const start = this.slides[this.slideCount].start;
    const stop = this.slides[this.slideCount].stop;

    this.clientsData = this.pageData.slice(start, stop);
  }

  handleClick(client: any) {
    console.log('handleClick', client);
    this.clientSpotlight = client;
  }
}

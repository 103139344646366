import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent {
  projectsData: any = ''

  constructor(private appService: AppService) {}

  ngOnInit(): void {
      this.appService.pageData.subscribe((page) => {
        this.projectsData = page['projects']
        console.log('projects', this.projectsData)
      })
  }

  routeTo(r: string) {
    this.appService.routeTo(r)
  }
}

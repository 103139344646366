import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppService } from '../../services/app/app.service';
import { ProjectsPhotoStepperComponent } from '../projects-photo-stepper/projects-photo-stepper.component';
import { ClientsSectionStepperComponent } from '../clients-section-stepper/clients-section-stepper.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  componentsList: any = {
    'projectsStepper': ProjectsPhotoStepperComponent,
    'clientsStepper': ClientsSectionStepperComponent
  }
  constructor(public dialog: MatDialog, private appService: AppService) {}

  ngOnInit(): void {
    console.log('modal loaded')
      this.appService.openModal.subscribe((modal: any) => {
        if (modal.type) {
          this.openDialog(modal)
        }
      })
  }


  openDialog(modal: any): void {
    const dialogRef = this.dialog.open(this.componentsList[modal.type], {
      width: modal?.width ? modal?.width : '70%',
      height: '100%',
      data: modal
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The dialog was closed');
      
    });
  }
}

<div class="projectsStepper">
  <mat-horizontal-stepper labelPosition="bottom" linear="false" #stepper>
    <mat-step [stepControl]="fg">
      <form *ngIf="fg" [formGroup]="fg">
        <ng-template matStepLabel>Choose a {{ data.selected }}</ng-template>
        <div *ngIf="data.projects.length >= 1" class="projectsStepper__card-container">
          <div
            (click)="handleSelect(stepper, project?.name, project?.pics)"
            *ngFor="let project of data.projects"
            class="cardWrap"
          >
            <div class="card">
              <div class="bgImg">
                <img [src]="project?.bkg" alt="" />
              </div>
              <div class="cardInfo">
                <h3 class="cardTitle">{{ project?.name }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data.projects.length === 0" class="projectsStepper__card-container">
          <h1>No photos available at this time.</h1>
        </div>
        <div *ngIf="data.projects.length >= 1">
          <button (click)="goNext($event, stepper)" mat-button>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="fg">
      <form *ngIf="items.length >= 1" [formGroup]="fg">
        <ng-template matStepLabel>{{ selectedPhotoName }} photos</ng-template>
        <div class="grid-container">
          <gallery gallerize class="grid-item" [items]="items"></gallery>
        </div>
        <div>
          <button mat-button (click)="goBack($event, stepper)">Back</button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div class="services">
  <div class="services__bkg-img">
    <div class="services__overlay"></div>
    <img [src]="servicesData?.bkg" alt="" />
    <h1>Services</h1>
    <h4>Exceeding Expectations, One Weld at a Time.</h4>
  </div>

  <div class="services__headline">
    <div class="services__headline__content">
     <i> "{{ servicesData?.mission }}"</i>
    </div>
  </div>

  <div class="services__container">
    <div class="services__container___title">
      <h2>OUR SERVICES</h2>
    </div>

    <div class="services__container__cards">
      <div class="services__container__cards__first-row">
        <div *ngFor="let tile of servicesData?.tiles" class="services__container__cards__card">
          <img [src]="tile.img" alt="" />
          <h1 class="card-title">
            {{ tile.name }}
          </h1>
          <span>{{ tile.description }}</span>
          <button class="button type1">
            <a href="tel:+13138413430"><span class="btn-txt">CALL FOR QUOTE (313-841-3430)</span></a>
            
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

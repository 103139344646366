<div class="projects-container">
  <div class="section-title">
    <h1><span>See</span> Our Projects</h1>
  </div>
  <div class="projects-tile-section">
    <div *ngFor="let project of projectsData" (click)="routeTo('projects')" class="projects-tiles">
      <div class="cardWrap">
        <div class="card">
          <div class="projects-overlay"></div>
          <img [src]="project?.bgImg" alt="" />
          <h1>{{ project?.name }}</h1>
        </div>
      </div>
    </div>
  </div>
  <app-marker letter="P" word="rojects" wordColor="blue"></app-marker>
</div>

import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-safety-section',
  templateUrl: './safety-section.component.html',
  styleUrls: ['./safety-section.component.scss']
})
export class SafetySectionComponent {
  safetyData: any = ''

  constructor(private appService: AppService) {}

  ngOnInit(): void {
      this.appService.pageData.subscribe((page) => {
        this.safetyData = page['safety']
        console.log('safe', this.safetyData)
      })
  }
}

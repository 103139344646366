import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Gallery, GalleryItem, GalleryRef, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { ViewChild } from '@angular/core';


@Component({
  selector: 'app-projects-photo-stepper',
  templateUrl: './projects-photo-stepper.component.html',
  styleUrls: ['./projects-photo-stepper.component.scss'],
})
export class ProjectsPhotoStepperComponent implements OnInit {
  fg: FormGroup = new FormGroup({});
  formPlaceHolder = '';
  selectedPhotoName = '';
  items: GalleryItem[] = [];
  galleryId = 'myLightbox';
  galleryRef = this.gallery.ref(this.galleryId);
  @ViewChild("stepper", { static: false })
  stepper: MatStepper | undefined;

  constructor(
    public dialogRef: MatDialogRef<ProjectsPhotoStepperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gallery: Gallery,
  ) {}

  ngOnInit(): void {
    console.log('data for projec stepper', this.data);
    this.fg = new FormGroup({
      selectedProject: new FormControl('', Validators.required),
    });
    this.items = [];
  }

  goBack(e: any, step: MatStepper) {
    e.preventDefault();
    this.items = [];
    this.galleryRef.reset();
    step.previous();
  }

  goNext(e: any, step: MatStepper) {
    e.preventDefault();
    step.next();
  }

  handleSelect(step: MatStepper, name: any, pics: any[]) {
    console.log('step', step.selectionChange);
    console.log('name', name);
    console.log('value', this.fg.value);
    this.selectedPhotoName = name
    console.log('picsssss', pics)
    pics.forEach((x) => {
      this.items.push(new ImageItem({
        src: x,
        thumb: x
      }))
    })
    this.galleryRef.load(this.items)
    if (this.items.length >= 1) {
      step.next()
    }
  }
}

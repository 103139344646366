<div class="maintenance">
  <vg-player>
    <video
      *ngIf="maintenanceData?.comingSoon"
      oncanplay="this.play()"
      onloadedmetadata="this.muted = true"
      [vgMedia]="$any(media)"
      #media
      class="maintenance_video"
      playsinline
      id="savsMaintenanceVideo"
      preload="auto"
      [autoplay]="true"
      [loop]="true"
    >
      <source [src]="maintenanceData?.comingSoon" type="video/mp4" />
    </video>
  </vg-player>
</div>

import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public pageData: any;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.appService.triggerLoader(false)
    }, 2000)
  }


}

import { Component } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  isMenuShowing = false;

  constructor(private appService: AppService) {
  }

  routeTo(url: string) {
    this.appService.routeTo(url);
    this.isMenuShowing = false;
  }

  showMenu() {
    this.isMenuShowing = !this.isMenuShowing;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isLoading: BehaviorSubject<any> = new BehaviorSubject(true);
  public pageData: BehaviorSubject<any> = new BehaviorSubject('');
  public videoListener: BehaviorSubject<any> = new BehaviorSubject('');
  public videoPlayer: BehaviorSubject<any> = new BehaviorSubject('');
  public openModal: BehaviorSubject<any> = new BehaviorSubject({
    type: '',
    data: {}
  });
  public appID = '_sws_';

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) {}

  triggerLoader(isShowing: boolean) {
    this.isLoading.next(isShowing);
  }

  retrieve(r: any) {
    return this.http.get(r);
  }

  public routeTo(r: any) {
    this.router.navigate([r]);
  }

  send(u: any, d: any) {
    return this.http.post(u, d);
  }

  setCookie(k: any, v: any) {
    this.cookieService.set(k, v);
  }

  deleteCookie(k: any) {
    this.cookieService.delete(k);
  }

  getData() {
    return this.retrieve(`/api/home`).subscribe((res) => {
      console.log('made api call');
      this.pageData.next(res);
      this.setCookie(this.appID, true);
      this.setLocalStorage(this.appID, res);
    });
    // return this.hasCookieAndStorage(this.appID)
    //   ? this.pageData.next(JSON.parse(this.getStorage(this.appID) ?? ''))
    //   : this.retrieve(`/api/home`).subscribe((res) => {
    //       console.log('made api call');
    //       this.pageData.next(res);
    //       this.setCookie(this.appID, true);
    //       this.setLocalStorage(this.appID, res);
    //     });
  }

  getCookie(k: any) {
    this.cookieService.get(k);
  }

  getAllCookies() {
    this.cookieService.getAll();
  }

  setLocalStorage(k: any, v: any) {
    localStorage.setItem(k, JSON.stringify(v));
  }

  deleteLocalStorage(k: any) {
    localStorage.removeItem(k);
  }

  hasCookieAndStorage(k: any): boolean {
    return this.cookieService.get(k) !== '' && localStorage.getItem(k) !== null;
  }

  getStorage(k: any) {
    return localStorage.getItem(k);
  }
}

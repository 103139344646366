import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss']
})
export class MarkerComponent {
  @Input() letter = '';
  @Input() word = '';
  @Input() wordColor = '';
}
